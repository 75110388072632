import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { renderElements } from "../components/elements"
import SEO from "../components/seo"

export const query = graphql`
  query{
    wpPage(slug: {eq: "app-datenschutz"}) {
        renderContent
    }
  }
`
const SecondPage = props => {
    return renderElements(props.data.wpPage.renderContent);
}

export default SecondPage